import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import classnames from 'classnames'

const AllContainer = styled.div``
const Inner = styled.div`
  ${tw`
   flex-col
   flex
   md:flex-row
   max-w-5xl
   my-0
   mx-auto
   lg:pt-6
   pt-0
  `}

  position: relative;
`

const Image = styled.img`
  ${tw`
  md:pr-4
  xl:pr-10
  w-full
  `}
  object-fit: contain;
`

const TextContainer = styled.div`
  ${tw`
    pt-5
    md:pt-0
    pb-8
    mx-0
    md:px-0
    md:pb-8
   
  `}
`

const ContainerDescriptionDown = styled.div`
  ${tw`
    text-base
    font-light
    leading-relaxed
    border'
  `}

  ul {
    padding-left: 18px;
  }
`

const ContainerContent = styled.div`
  ${tw`
    text-charcoal
    font-sans 
    w-full
    lg:w-3/5
  `}
`

function ColumnWithImage({
  title,
  subtitleUp,
  descriptionUp,
  subtitleDown,
  descriptionDown,
  image,
}) {
  return (
    <AllContainer>
      <Inner>
        <div className="lg:w-2/5 w-full ">
          <Image src={image} />
        </div>

        <ContainerContent>
          <div>
            <TextContainer>
              <div
                className={classnames(
                  'text-35px',
                  'font-extrabold',
                  'pb-3',
                  'lg:pb-5'
                )}
              >
                {title}
              </div>
              <div className="pb-3 md:pb-5 lg:pb-6">
                <div className={classnames('text-2xl', 'font-bold', 'pb-3')}>
                  {subtitleUp}
                </div>
                <div
                  className={classnames(
                    'text-base',
                    'lg:max-w-lg',
                    'font-light',
                    'leading-relaxed'
                  )}
                >
                  {descriptionUp}
                </div>
              </div>
              <div className="font-sans">
                <div className={classnames('text-2xl', 'font-bold', 'pb-3')}>
                  {subtitleDown}
                </div>
                <ContainerDescriptionDown
                  dangerouslySetInnerHTML={{ __html: descriptionDown }}
                ></ContainerDescriptionDown>
              </div>
            </TextContainer>
          </div>
        </ContainerContent>
      </Inner>
    </AllContainer>
  )
}

export default ColumnWithImage
